import { MutableRefObject, useRef, useState } from 'react';

type DropPosition = 'top' | 'bottom' | null;

export type useSectionDragAndDropProps = {
  sectionId: string;
  index: number;
  onReorder: (fromIndex: number, toIndex: number) => void;
  activeDraggedSectionRef: MutableRefObject<{ sectionId: string; index: number } | null>;
};

export const useSectionDragAndDrop = ({ sectionId, index, onReorder, activeDraggedSectionRef }: useSectionDragAndDropProps) => {
  const [dropIndicatorPosition, setDropIndicatorPosition] = useState<DropPosition | null>(null);
  const sectionRef = useRef<HTMLLIElement>(null);

  const handleDragStart = (event: React.DragEvent) => {
    event.dataTransfer.setData('text/plain', JSON.stringify({ sectionId, index }));
    event.dataTransfer.effectAllowed = 'move';
    activeDraggedSectionRef.current = { sectionId, index };
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();

    if (!activeDraggedSectionRef.current || activeDraggedSectionRef.current.sectionId === sectionId) {
      setDropIndicatorPosition(null);
      return;
    }

    if (sectionRef.current) {
      const elementRect = sectionRef.current.getBoundingClientRect();
      const elementRectRelativeY = event.clientY - elementRect.top;
      const isTopHalf = elementRectRelativeY < elementRect.height / 2;
      setDropIndicatorPosition(isTopHalf ? 'top' : 'bottom');
    }
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
    setDropIndicatorPosition(null);
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const draggedItem = activeDraggedSectionRef.current;
    if (!draggedItem) {
      return;
    }

    if (draggedItem.sectionId !== sectionId) {
      onReorder(draggedItem.index, dropIndicatorPosition === 'bottom' ? index + 1 : index);
    }
    setDropIndicatorPosition(null);
  };

  const handleDragEnd = (event: React.DragEvent) => {
    event.preventDefault();
    setDropIndicatorPosition(null);
    activeDraggedSectionRef.current = null;
  };

  return {
    sectionRef,
    dropIndicatorPosition,
    dragHandlers: {
      draggable: true,
      onDragStart: handleDragStart,
      onDragOver: handleDragOver,
      onDragLeave: handleDragLeave,
      onDrop: handleDrop,
      onDragEnd: handleDragEnd,
    },
  };
};

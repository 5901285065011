import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../../../grid/reduxStore/Store';

import { IconButton, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IcoLock from 'components/icon/icons/IcoLock';
import IcoLockOpen from 'components/icon/icons/IcoLockOpen';
import IcoHelp from 'components/icon/icons/IcoHelp';

import { EditorDefaultSidePanelType, setActiveSidePanel } from '../../../../grid/reduxStore/blockStyleSettingsSlice';
import { BlockContent, selectSectionIdByBlockId } from 'components/editor/grid/reduxStore/editorSlice';

import { SecondaryMenu, SecondaryMenuSectionTitle, TextInputBoxSingleCol, NumberInputBoxSingleCol } from '../../../component';

import { SelectionContext } from '../../../../GridDndEditor/SelectedBlockInfoProvider';
import { useBlockContentWithSectionId } from 'components/editor/Sections/useBlockContent';
import { useVideoBlockSettings } from 'components/editor/hooks/useVideoBlockSettings';
import { ConsoleLogger } from 'utils/ConsoleLogger';
import { VideoContent } from 'components/editor/videos/video';

const getVideoUrlFromBlockContent = (blockContent: BlockContent | undefined): string => {
  return (blockContent?.contentObject as VideoContent)?.url ?? '';
};

export function VideoBlockSettings() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { selectedBlockIdByIcon } = useContext(SelectionContext);
  const blockId = selectedBlockIdByIcon as string;
  const sectionId = useSelector((state: RootState) => selectSectionIdByBlockId(state, blockId));
  const blockContent = useBlockContentWithSectionId(sectionId ?? '', blockId);
  const { updateVideoContentFromUrl } = useVideoBlockSettings();

  const [videoUrl, setVideoUrl] = useState(getVideoUrlFromBlockContent(blockContent));
  const [aspectRatioLock, changeAspectRatioLock] = useState(false);

  useEffect(() => {
    setVideoUrl(getVideoUrlFromBlockContent(blockContent));
  }, [blockId, blockContent]);

  const handleVideoWidthChange = () => {
    //TODO will be implemented in the next PRs
  };

  const handleVideoHeightChange = () => {
    //TODO will be implemented in the next PRs
  };

  const handleVideoUrlChange = async (newUrl: string) => {
    if (newUrl === getVideoUrlFromBlockContent(blockContent)) {
      return;
    }

    try {
      await updateVideoContentFromUrl(newUrl);
    } catch (error) {
      console.log(error);
      //TODO: Extended errors will be implemented in the next PRs
      ConsoleLogger.error('VideoBlockSettings - Error updating video content from URL', error);
    }
  };

  const handleBackButtonClick = () => {
    dispatch(setActiveSidePanel({ type: EditorDefaultSidePanelType.EDITOR_DEFAULT_SIDE_PANEL }));
  };

  return (
    <SecondaryMenu
      testId="video-block-settings-component"
      menuTitle={t('document.grid.video.design.title')}
      backButtonOnClick={handleBackButtonClick}
    >
      <Stack rowGap="16px" width="100%">
        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.video.design.general')}</SecondaryMenuSectionTitle>

        <Stack gap={1} direction="row" alignItems="end">
          <TextInputBoxSingleCol
            title={
              <Stack gap={1} direction="row">
                {t('document.grid.video.design.url')}
                <IcoHelp id="video-help" width={20} height={20} />
              </Stack>
            }
            placeholderText={t('document.grid.video.design.url_placeholder')}
            onChange={(value) => setVideoUrl(value)}
            onBlur={handleVideoUrlChange}
            onPaste={handleVideoUrlChange}
            value={videoUrl}
            testId="video-url-input"
          />
        </Stack>

        <SecondaryMenuSectionTitle hasUnderLine={true}>{t('document.grid.video.design.display')}</SecondaryMenuSectionTitle>

        <Stack gap={1} direction="row" alignItems="end">
          <Stack direction="row" gap={2}>
            <NumberInputBoxSingleCol
              title={t('document.grid.video.design.width')}
              onChange={handleVideoWidthChange}
              value={blockContent?.blockConfig.width}
              endAdornmentText="px"
              testId="video-width-input"
            />
            <NumberInputBoxSingleCol
              title={t('document.grid.video.design.height')}
              onChange={handleVideoHeightChange}
              value={blockContent?.blockConfig.height}
              endAdornmentText="px"
              testId="video-height-input"
            />
          </Stack>

          <IconButton data-testid="aspect-ratio-lock-button" onClick={() => changeAspectRatioLock(!aspectRatioLock)}>
            {aspectRatioLock ? <IcoLock /> : <IcoLockOpen />}
          </IconButton>
        </Stack>
      </Stack>
    </SecondaryMenu>
  );
}

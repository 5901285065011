import { useTranslation } from 'react-i18next';
import { KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState, rootStore, useAppDispatch } from '../grid/reduxStore/Store';
import { updateSectionTitle } from '../grid/reduxStore/editorSlice';
import { useSocketClient } from '../../../providers/SocketContext';
import { AcknowledgmentResponseStatus, DefaultSocketResponseType, SectionEvents } from '../../../services/socket/SocketEvents';
import { setSaveStatus } from '../grid/reduxStore/saveStatusSlice';
import { DocumentSaveStatus } from '../shared/models/DocumentSaveStatus';
import { useDocumentLockedModal } from '../modals/DocumentLockedModalProvider';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';

const SectionTitleInput = ({ sectionId }: { sectionId: string }) => {
  const sectionTitle = useSelector((state: RootState) => state.gridBlockReducer.sections[sectionId].title);
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const oldTitleRef = useRef<string | undefined>(undefined);

  const dispatch = useAppDispatch();
  const { socketClient } = useSocketClient();
  const { checkDocumentLockStatus } = useDocumentLockedModal();
  const { t } = useTranslation();

  const textFieldStyles = useMemo(
    () => ({
      '& .MuiOutlinedInput-root': {
        padding: '8px 12px',
        fontSize: '20px',
        fontWeight: 600,
      },
      '& .MuiOutlinedInput-input': {
        padding: 0,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0000003B',
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
    }),
    []
  );

  const setSectionTitleState = (title: string) => {
    dispatch(
      updateSectionTitle({
        sectionId: sectionId,
        sectionTitle: title,
      })
    );
  };

  const handleTitleChangeSocketCallback = (response: DefaultSocketResponseType, newTitle: string) => {
    const oldTitle = oldTitleRef.current;
    if (response.status === AcknowledgmentResponseStatus.OK) {
      oldTitleRef.current = newTitle;
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVED }));
      return;
    }

    if (oldTitle !== undefined) {
      setSectionTitleState(oldTitle);
    }
    dispatch(setSaveStatus({ status: DocumentSaveStatus.NOT_SAVED }));
    checkDocumentLockStatus(response.errorCode);
  };

  const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      inputFieldRef.current?.blur();
    }
  };

  const getTrimmedOrDefaultTitleIfEmpty = (title: string): string => {
    const trimmedTitle = title.trim();
    return trimmedTitle || t('editor.default_content_section_title');
  };

  const persistSectionTitle = (title: string) => {
    const oldTitle = oldTitleRef.current;
    const newTitle = getTrimmedOrDefaultTitleIfEmpty(title);

    const shouldUpdateSectionTitle = oldTitle !== newTitle;
    if (shouldUpdateSectionTitle) {
      setSectionTitleState(newTitle);
      dispatch(setSaveStatus({ status: DocumentSaveStatus.SAVING }));
      socketClient.publish(SectionEvents.SECTION_CHANGED, { id: sectionId, title: newTitle }, (response) =>
        handleTitleChangeSocketCallback(response, newTitle)
      );
    }
  };

  const persistLastKnownSectionTitle = () => {
    const latestSectionTitle = rootStore.getState().gridBlockReducer.sections[sectionId].title;
    persistSectionTitle(latestSectionTitle);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', persistLastKnownSectionTitle);
    oldTitleRef.current = sectionTitle;

    return () => {
      // We persist the current section title on unmount and page refresh; This is to account for the lazy load we do for Sections which
      // will make this component to unmount while scrolling the page and if the user refreshes the page and hadn't saved the title
      window.removeEventListener('beforeunload', persistLastKnownSectionTitle);
      persistLastKnownSectionTitle();
    };
  }, []);

  return (
    <Tooltip title={sectionTitle}>
      <TextField
        inputRef={inputFieldRef}
        sx={textFieldStyles}
        variant="outlined"
        size="small"
        fullWidth
        data-testid="section-title-textfield"
        onChange={(e) => setSectionTitleState(e.target.value)}
        onKeyDown={blurOnEnter}
        inputProps={{ maxLength: 100, 'data-testid': 'section-title-textfield-input' }}
        placeholder={t('editor.default_content_section_title')}
        onBlur={() => persistLastKnownSectionTitle()}
        value={sectionTitle}
      />
    </Tooltip>
  );
};

export default SectionTitleInput;

type Props = {
  videoId: string;
  width: number;
  height: number;
  title: string;
};

const YouTubeEmbed: React.FC<Props> = ({ videoId, width, height, title }) => {
  return (
    <div className="video-container" style={{ width, height }}>
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}`}
        title={title}
        style={{ border: 0 }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeEmbed;

import SettingsTheme from 'muiTheme/SettingsTheme';
import { Box, Card, CardContent, Stack, ThemeProvider } from '@mui/material';
import PageLoader from 'components/page-loader/page-loader';
import ProposifyLogo from '../../images/proposify-logo.png';
import BillingCardHeader from 'pages/settings/utils/BillingCardHeader';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CreateYourWorkspaceForm from './CreateYourWorkspaceForm';

const CreateYourWorkspacePage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const accountId = state?.accountId ? state.accountId : 1;

  return (
    <PageLoader isLoading={false} isLoaded={true}>
      <ThemeProvider theme={SettingsTheme}>
        <Box sx={{ backgroundColor: '#F3F8FC' }} data-testid="create-your-workspace-page-container" px={3}>
          <img
            src={ProposifyLogo}
            data-testid="proposify-logo"
            width="120px"
            alt="Proposify Logo"
            style={{ margin: '0 auto', display: 'block', marginTop: '36px', marginBottom: '24px' }}
          />
          <Card
            data-testid="create-your-workspace-page-content"
            sx={{
              borderRadius: '24px',
              boxShadow: 'none',
              padding: '40px 40px 16px',
              margin: '0 auto 36px',
              maxWidth: '600px',
            }}
          >
            <BillingCardHeader
              title={t('signup.create_your_workspace.title')}
              subheader={
                <Trans
                  i18nKey="signup.create_your_workspace.sub_header"
                  components={{
                    bold: <strong />,
                  }}
                />
              }
              subheaderColor="text.primary"
            />
            <CardContent sx={{ pt: 2, px: 0 }}>
              <Stack direction={{ sm: 'column' }} gap={6}>
                <CreateYourWorkspaceForm
                  initialData={{ accountId: accountId, companyName: '', subdomainName: '' }}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onSuccess={function (): void {}}
                  onFailure={function (response): void {
                    throw new Error('Function not implemented.' + response);
                  }}
                />
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </ThemeProvider>
    </PageLoader>
  );
};

export default CreateYourWorkspacePage;
